<template>
	<Transition name="slide-fade" appear>
        <div class="w-A4W m-auto bg-white  container"> 
            <div class="rounded shadow-t3 items-center relative md:mt-0">


                <div class="w-full p-8 flex justify-between shadow-t3">
                    <h2 class="text-xl text-t3 font-bold sm:text-3xl dark:text-white">
                        Document Preview
                    </h2>
                    
                </div>

                <div class="w-full m-auto text-center relative" id="certificate">
                    <PDFViewer
                        :source="url"
                        style="height: 100vh; width: 100vw"
                        @download="handleDownload"
                        />
                </div>

            

            </div>
        </div>
    </Transition>
</template>

<script>
// import { watchEffect } from 'vue'
import { mapState, mapActions} from 'vuex'
import PDFViewer from 'pdf-viewer-vue'


    export default {
        props:{
            id:{
                type:Number
            }
        },
        data(){
            return {
                url:null
            }
        },  
        components:{
            PDFViewer,
        },

        methods: {
        ...mapActions({
            getDocument: 'Documents/getDocument',
				
        }),
      

        
         },

        computed: {
        ...mapState({
            document: state => state.Documents.document,
			
        }),
       
		},

        created() {
			this.getDocument(this.id).then(()=>{
                this.url = document.document
            })
	}
    }
</script>

<style>
@page {
    margin: 0;
    padding:0;
    overflow: hidden;
    size:portrait;
    height:100%;
}



</style>